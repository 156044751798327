.layer-switcher {
    top: 4.4em;
    left: 0.5em;
    right: unset;

    button {
        width: 1.375em;
        height: 1.375em;
        background-position: 0;
        background-size: contain;
    }
}
